
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
 function Single() {


	const allParams = useParams();

	console.log(allParams);


	var id = allParams.id;

	const [post, setPost] = useState([]);

	useEffect(() => {
		fetch("https://webeetec.com/itsharks24/blog/api/singlepost.php?id=" + id)
			.then(r => r.json())
			.then(r => {
				console.log(r)
				setPost(r)

			})
	} , id)
	return (
		<section class="container">


			<div class="wrapper clear">
				<div class="contentLeft">
		

					{
						post.map((p) => {
							return (

								<>

									<div class="singlePostMeta">
										<div class="singlePostTime">{p.date}</div>
										<h1>{p.title}</h1>
										<a href="#" class="singlePostCategory">{p.category}</a>
									</div>
									<div class="singlePostWrap">
										<div class="singlePostImg">
											<img src={"https://webeetec.com/itsharks24/blog/admin/" + p.image} alt="Francoise img" />
										</div>
										<p>{p.description}</p>

									</div>

								</>
							)
						})
					}





				</div>


				<div class="sidebarRight">
					<div class="sidebar-widget">
						<h3>About us</h3>
						<div class="aboutMeWidget">
							<img src="assets/images/ourlogo.png" alt="Francoise img" />
							<p>A training company specialized in teaching programming languages, networking and computer science to ensure that you get practical experience in the field of software.</p>
						</div>
					</div>





				</div>
			</div>
			<div class="clear"></div>

		</section>
	);
}

export default Single;