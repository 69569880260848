import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import Single from './components/Single';

import {BrowserRouter , Routes , Route} from 'react-router-dom'

function App() {
  return (
      <>
      <BrowserRouter>
         <Header />


        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='single/:id' element={<Single />} />
        </Routes>

      <Footer />

      </BrowserRouter>
     

      

     
      </>
  );
}

export default App;
