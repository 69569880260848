function Footer() {
    return ( 
        <footer id="footer">
		<div className="footerSocial">
			<div className="wrapper clear">
				<div className="footerSocialItem">
					<a href="#" className="">
						<i className="fa fa-instagram"></i>
						Instagram <br />
						10 537
					</a>
				</div>
				<div className="footerSocialItem">
					<a href="#" className="">
						<i className="fa fa-facebook"></i>
						facebook <br />
						103 537
					</a>
				</div>
				<div className="footerSocialItem">
					<a href="#" className="">
						<i className="fa fa-twitter"></i>
						Twitter <br />
						27 129
					</a>
				</div>
				<div className="footerSocialItem">
					<a href="#" className="">
						<i className="fa fa-heart"></i>
						bloglovin <br />
						98 122
					</a>
				</div>
				<div className="footerSocialItem">
					<a href="#" className="">
						<i className="fa fa-pinterest"></i>
						Pinterest <br />
						10 641
					</a>
				</div>
				<div className="footerSocialItem">
					<a href="#" className="">
						<i className="fa fa-google-plus"></i>
						google + <br />
						17 324
					</a>
				</div>
			</div>
		</div>
		<div className="wrapper">
			
			<p className="copyright">© 2023 IT Sharks 30 Change Copy right</p>
		</div>	
	</footer>
     );
}

export default Footer;